<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onReset="search = {}"
        @onSearch="onSearch"
        @onChangePage="changeCurrentPage"
        @onExportData="download"
        @onHandle="tableHandle">
    </list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [{
        prop: 'type',
        placeholder: '筛选问题分类',
        tag: 'select',
        options: [],
        value: 'id',
        label: 'name',
      }, {
        prop: 'school_id',
        placeholder: '筛选校区',
        tag: 'select',
        options: [],
        value: 'id',
        label: 'school_name',
        change: (val, search) => {
          delete search['grade_id']
        }
      }, {
        prop: 'grade_id',
        placeholder: '筛选年级',
        tag: 'select',
        options: [],
        value: 'id',
        label: 'grade_name'
			}, {
				prop: 'is_reply',
				placeholder: '筛选是否回复',
				tag: 'select',
				options: [{
					value: 0,
					label: '否'
				}, {
					value: 1,
					label: '是'
				}],
      }, {
        prop: 'start_date',
        tag: 'datePicker',
        property: {
          size: 'small',
          style: 'margin-right:20rem;',
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期'
        }
      }],
      // 表格配置
      tableConfig: [
				{prop: 'id', label: '序号'},
        {prop: 'type', label: '问题分类'},
        {prop: 'content', label: '反馈内容'},
        {prop: 'school_name', label: '反馈校区'},
        {prop: 'grade_name', label: '反馈年级'},
				{prop: 'created_at', label: '反馈时间'},
				{prop: 'is_reply', label: '是否回复'},
        {label: '操作', handle: true, width: '100rem', buttons: [{ type:"view", text:"查看" }]},
      ],
      search: {},
      total: 0,
      // 表格中的数据
      tableData: [],
    }
  },
  computed: {
    ...mapState(["page"])
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData()
    },
		/**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      let searchConfig = JSON.parse(JSON.stringify(search))
      if (JSON.stringify(searchConfig) !== '{}') {
        if (searchConfig.start_date) {
          searchConfig.start_time = search.start_date[0].replace(/(\d{4})(\d{2})/, '$1-$2')
          searchConfig.end_time = search.start_date[1].replace(/(\d{4})(\d{2})/, '$1-$2')
          delete searchConfig.start_date
        }
      }
      this.loading = true;
      this.$_axios2.get('api/logistics/student-feedback/list', {params: {...searchConfig, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false);
    },
    download() {
      let {search, page} = this
      let searchConfig = JSON.parse(JSON.stringify(search))
      if (JSON.stringify(searchConfig) !== '{}') {
        if (searchConfig.start_date) {
          searchConfig.start_time = search.start_date[0].replace(/(\d{4})(\d{2})/, '$1-$2')
          searchConfig.end_time = search.start_date[1].replace(/(\d{4})(\d{2})/, '$1-$2')
          delete searchConfig.start_date
        }
      }
      this.$_axios2.post('api/logistics/student-feedback/export', searchConfig).then(res => {
        tools.download('学生反馈', res.data)
      })
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val
      this.getData()
    },
		tableHandle(row, type) {
			console.log(row, type)
			if (type.type === 'view') {
				if (row.is_reply)
					this.$router.push(`./look?id=${row.id}`)
				else
					this.$router.push(`./look1?id=${row.id}`)
			}
			if (type.type === "reply")
				this.$router.push(`./look?id=${row.id}`)
    }
  },
  created() {
    this.$_axios2('api/pre-course-select/options/schools').then(res => {
      this.searchConfig[1].options = res.data.data
    })
    this.$_axios2('api/common/screen/grade').then(res => {
      this.searchConfig[2].options = res.data.data
    })
    this.$_axios2('api/feedback/problem/type').then(res => {
      this.searchConfig[0].options = res.data.data.student
    })

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-range-separator {
  width: 24rem !important;
}
</style>
